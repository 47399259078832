<template>
    <div class="bg-gradient-primary">
        <div class="container p-4">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8 col-xl-7">
                    <div class="card shadow-lg o-hidden border-0 my-5">
                        <div class="card-body text-center text-muted">
                            <div v-if="loading">
                                <p class="p-4">Please wait...</p>
                            </div>
                            <div v-else>
                                <p class="p-4">
                                    Success! Your account has been confirmed!
                                </p>
                                <router-link to="/login">← Login</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '@/services/api.service'

export default {
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        confirmAccount() {
            const token = this.$route.params.token

            ApiService.confirmAccount(token).then(
                (response) => {
                    this.$bvModal
                        .msgBoxOk('Success! You can now login...', {
                            title: 'Success',
                            centered: true,
                        })
                        .then((success) => {
                            this.loading = false
                        })
                },
                (error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.$router
                                .push({ name: 'login' })
                                .catch((err) => {})
                        })
                }
            )
        },
    },
    mounted() {
        this.confirmAccount()
    },
}
</script>
